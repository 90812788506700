import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { BaseComponent } from 'src/app/core/components/base-component';
import { MpService } from 'src/app/main/mp/mp.service';
@Component({
  selector: 'app-succes',
  templateUrl: './succes.component.html',
  styleUrls: ['./succes.component.scss']
})
export class SuccesComponent extends BaseComponent implements OnInit {

    successLottie: AnimationOptions = {
        path: '/assets/success.json',
        loop: false,
      };
  paymentStatus: string = '';
  paymentStatusDetail: string = '';
  responseData: string = '';
  paymentId: string | null = '';
  constructor(
    private mpService: MpService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(queryParams => {
      this.paymentId = queryParams.get("payment_id");
      console.log("PayId:" + this.paymentId);
      if (this.paymentId) this.callApi(this.paymentId);
    })
  }

  callApi(paymentId: string) {
    this.mpService.getPaymentStatus(paymentId).subscribe(
      (response: any) => {
        if (response.error) {
          /*this.errorGettingData(response);
          this.disableLoading();*/
        } else {
          console.log("Respuesta");
          console.log(response);
        //   this.paymentStatus = response.data.status;
        //   this.paymentStatusDetail = response.status_detail;
        //   this.responseData = JSON.stringify(response);
        }
      });
  }

  reset() {
    this.router.navigate(['/'])
  }
}
