import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginRegisterPageComponent } from './login-register-page/login-register-page.component';
import { LoginChangePasswordComponent } from './login-change-password/login-change-password.component';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { FormatPasswordComponent } from './format-password/format-password.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';

@NgModule({
    declarations: [LoginRegisterPageComponent, LoginChangePasswordComponent, FormatPasswordComponent, DeleteAccountComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AuthenticationRoutingModule,
    ]
})
export class AuthenticationModule {
}
