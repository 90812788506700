import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './auth/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isEditable = false;
  isLinear = true;

  constructor(private authService: AuthenticationService) {
  }

  ngOnInit(): void {
      this.authService.checkLogin();
  }
}
