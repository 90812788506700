import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "src/environments/environment";
import {AuthenticationService} from "../../auth/authentication.service";

@Injectable({
  providedIn: "root"
})
export class DataService {
  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {}

  getBrands(branchId) {
    return this.http.get(environment.apiUrl + "/commons/vehicles/" + branchId + "/brands");
  }

  getYears(modelId) {
    return this.http.get(environment.apiUrl + "/commons/vehicles/" + modelId + "/years");
    //return this.http.get(environment.apiUrl + "/" + modelId + "/years");
  }

  getModels(brandId) {
    return this.http.get(environment.apiUrl + "/commons/vehicles/" + brandId + "/models");
  }

  getVersions(modelId,yearId) {
    return this.http.get(environment.apiUrl + "/commons/vehicles/" + modelId + "/" + yearId + "/versions");
  }

  getDocumentTypeIds() {
    return this.http.get(environment.apiUrl + "/commons/data/document-types");
  }

  getProducts() {
    return this.http.get(environment.apiUrl + "/commons/products");
  }

  getClients() {
    return this.http.get(environment.apiUrl + "/vehicles/clients");
  }

  getProducer(producerId) {
    return this.http.get(environment.apiUrl + "/commons/producer/" + producerId + "?include=clients,clients.vehicles,clients.vehicles.version,clients.vehicles.year,clients.vehicles.model,clients.vehicles.model.brand");
  }

  getBranches() {
    return this.http.get(environment.apiUrl + "/commons/vehicles/branches");
  }

  getStates() {
    return this.http.get(environment.apiUrl + "/commons/data/1/states");
  }

  getCities(stateId) {
    return this.http.get(environment.apiUrl + "/commons/data/" + stateId + "/cities");
  }

  getTypes() {
    return this.http.get(environment.apiUrl + "/commons/products/types");
  }

  getClient(clientId) {
    return this.http.get(environment.apiUrl + "/vehicles/clients/" + clientId + "?include=vehicles,vehicles.version,vehicles.year,vehicles.model,vehicles.model.brand,clientGender,vehicles.documents");
  }

  getMyClient() {
    return this.http.get(environment.apiUrl + "/vehicles/my-client");
  }

  getGenders() {
    return this.http.get(environment.apiUrl + "/commons/genders");
    }
  uploadFile(field, file) {
    let fd = new FormData();
    fd.append(field, file);

    let headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    return this.http.post(environment.apiUrl + "/commons/upload", fd, {headers});
  }

  submit(values) {
    // return this.http.post(environment.apiUrl + (this.authenticationService.isLoggedIn
    //   ? "/order-with-client"
    //   : "/order-web"), values);
    return this.http.post(environment.apiUrl + "/web/orders/order-web", values);
  }
}