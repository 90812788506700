import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DotsComponent } from './components/dots/dots.component';

@NgModule({
    declarations: [
     DotsComponent
      ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DotsComponent    
    ]
})
export class SharedModule
{
}
