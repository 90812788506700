<form [formGroup]="form">
  <div *ngIf="step == 'client-details'">
    <div class="header">
      <p *ngIf="!showData">
        Estás contratando: {{ productName }} para {{ brandName }},
        {{ modelName }}, {{ yearName }}, {{ versionName }}
      </p>
      <p *ngIf="showData">Estás contratando: {{ productName }}</p>
      <h2>Datos personales</h2>
      <p *ngIf="this.garageFromWP()">VALOR DEL SEGURO: ${{ garagePrice }}</p>
      <app-dots [steps]="stepsCount" [active]="currentStep"></app-dots>
    </div>
    <div class="row narrow-gap">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Apellido <strong>*</strong></label>
          <input
            type="text"
            class="form-control"
            formControlName="lastName"
            maxlength="191"
            (input)="this.clearClientErrorMessage()"
          />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label>Nombre <strong>*</strong></label>
          <input
            type="text"
            class="form-control"
            formControlName="firstName"
            maxlength="191"
            (input)="this.clearClientErrorMessage()"
          />
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>Correo electrónico <strong>*</strong></label>
      <input
        type="text"
        class="form-control"
        formControlName="email"
        maxlength="200"
      />
      <p class="alert-prod" *ngIf="productor">
        Si no pones el mail del cliente deberás reenviar el certificado de
        cobertura inmediatamente que lo recibas ya que son pólizas de un día
        muchas veces.
      </p>
    </div>

    <div class="form-group">
      <label>Celular <strong>*</strong></label>
      <input
        type="text"
        class="form-control"
        formControlName="phone"
        maxlength="10"
        oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
        (input)="this.validateCellPhone()"
      />
      <span
        class="control-alert"
        [ngClass]="
          this.showPhoneAlert
            ? 'control-alert-visible'
            : 'control-alert-invisible'
        "
      >
        Ingresá 10 números
      </span>
    </div>

    <div class="form-group">
      <label>Tipo y número de documento <strong>*</strong></label>
      <div class="row">
        <div class="col-4">
          <select fromControlName="documentTypeId" class="form-select">
            <option *ngFor="let type of documentTypes" [value]="type.id">
              {{ type.name }}
            </option>
          </select>
        </div>
        <div class="col-8">
          <input
            type="text"
            class="form-control"
            formControlName="documentNumber"
            maxlength="8"
            oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
            (input)="this.clearClientErrorMessage()"
          />
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>Fecha de nacimiento <strong>*</strong></label>
      <input type="date" class="form-control" formControlName="birthdate" />
    </div>

    <div class="form-group">
      <label>Sexo <strong>*</strong></label>
      <div class="row" *ngFor="let gender of genders">
        <label class="form-check">
          <input
            type="radio"
            name="gender"
            [value]="gender.id"
            formControlName="gender"
          />
          {{ gender.name }}
        </label>
      </div>
    </div>

    <div class="error" *ngIf="!!clientDetailsError">
      {{ clientDetailsError }}
    </div>

    <div class="nav-buttons">
      <button type="button" class="btn btn-secondary" *ngIf="this.garageFromWP()" (click)="prevStep('vehicle')">
        <fa-icon [icon]="faChevronLeft"></fa-icon> Anterior
      </button>
      <button type="button" class="btn btn-secondary" *ngIf="!this.garageFromWP()" (click)="goPrevious()">
        <fa-icon [icon]="faChevronLeft"></fa-icon> Anterior
      </button>

      <button
        type="button"
        class="btn btn-primary"
        (click)="clientDetailsNextOnClick()"
      >
        Siguiente <fa-icon [icon]="faChevronRight"></fa-icon>
      </button>
    </div>
  </div>

  <div *ngIf="step == 'address'">
    <div class="header">
      <h2>Dirección</h2>
      <p *ngIf="this.garageFromWP()">VALOR DEL SEGURO: ${{ garagePrice }}</p>
      <app-dots [steps]="stepsCount" [active]="currentStep"></app-dots>
    </div>

    <div class="form-group">
      <label>Calle y número <strong>*</strong></label>
      <input
        type="text"
        class="form-control"
        formControlName="address"
        maxlength="200"
        (input)="this.clearAddressErrorMessage()"
      />
    </div>

    <div class="form-group">
      <label>Piso - Departamento - Bloque</label>
      <input
        type="text"
        class="form-control"
        formControlName="address2"
        maxlength="200"
        (input)="this.clearAddressErrorMessage()"
      />
    </div>

    <div class="form-group">
      <label>Provincia <strong>*</strong></label>
      <ng-select
        formControlName="stateId"
        (change)="loadCities()"
        class="custom primary required"
        [searchable]="true"
        [clearable]="false"
        [notFoundText]="'No se encontraron datos'"
        appearance="underline"
      >
        <ng-option *ngFor="let state of states" [value]="state.id">
          {{ state.name }}
        </ng-option>
      </ng-select>
    </div>

    <div class="form-group">
      <label>Ciudad <strong>*</strong></label>
      <ng-select
        formControlName="cityId"
        class="custom primary required"
        [searchable]="true"
        [clearable]="false"
        [notFoundText]="'No se encontraron datos'"
        appearance="underline"
      >
        <ng-option *ngFor="let city of cities" [value]="city.id">
          {{ city.name }}
        </ng-option>
      </ng-select>
    </div>

    <div class="error" *ngIf="!!addressError">{{ addressError }}</div>

    <div class="nav-buttons">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="prevStep('client-details')"
      >
        <fa-icon [icon]="faChevronLeft"></fa-icon> Anterior
      </button>

      <button
        type="button"
        *ngIf="!this.garageFromWP()"
        class="btn btn-primary"
        (click)="addressNextOnClick()"
      >
        Siguiente <fa-icon [icon]="faChevronRight"></fa-icon>
      </button>
      <button
      type="button"
      *ngIf="this.garageFromWP()"
      [ngClass]="{
        'btn btn-primary-dark': true,
        'loading spinner-white': saving
      }"
      (click)="submitForm()"
    >
      Finalizar <fa-icon [icon]="faChevronRight"></fa-icon>
    </button>
    </div>
  </div>

  <div *ngIf="step == 'vehicle'">
    <div class="header">
      <p *ngIf="this.garageFromWP()">Estás contratando: {{ productName }}</p>
      <h2>Datos del vehículo</h2>
      <app-dots [steps]="stepsCount" [active]="currentStep"></app-dots>
    </div>

    <div class="form-group">
      <label>Fecha y hora de inicio de vigencia <strong>*</strong></label>

      <div class="row">
        <div class="col-6">
          <input
            type="date"
            class="form-control"
            formControlName="startDate"
            [min]="this.minDate"
            [max]="this.maxDate"
            (input)="this.clearVehicleErrorMessage()"
          />
        </div>
        <div class="col-6">
          <input
            type="time"
            class="form-control"
            formControlName="startTime"
            (input)="this.clearVehicleErrorMessage()"
          />
        </div>
      </div>
    </div>
    <div class="form-group">
      <label>Marca <strong>*</strong></label>
      <ng-select
        formControlName="brandId"
        (change)="selectBrandCombo($event)"
        class="custom primary required"
        [searchable]="true"
        [clearable]="false"
        [notFoundText]="'No se encontraron datos'"
        appearance="underline"
      >
        <ng-option *ngFor="let brand of brands" [value]="brand.id">
          {{ brand.name }}
        </ng-option>
      </ng-select>
    </div>
    <div class="form-group">
      <label>Modelo <strong>*</strong></label>
      <ng-select
        formControlName="modelId"
        (change)="selectModelCombo($event)"
        class="custom primary required"
        [searchable]="true"
        [clearable]="false"
        [notFoundText]="'No se encontraron datos'"
        appearance="underline"
      >
        <ng-option *ngFor="let model of models" [value]="model.id">
          {{ model.name }}
        </ng-option>
      </ng-select>
    </div>

    <div class="form-group">
      <label>Año <strong>*</strong></label>
      <ng-select
        formControlName="yearId"
        (change)="selectYearCombo($event)"
        class="custom primary required"
        [searchable]="true"
        [clearable]="false"
        [notFoundText]="'No se encontraron datos'"
        appearance="underline"
      >
        <ng-option *ngFor="let year of years" [value]="year.id">
          {{ year.name }}
        </ng-option>
      </ng-select>
    </div>

    <div class="form-group">
      <label>Versión <strong>*</strong></label>
      <ng-select
        formControlName="versionId"
        (change)="selectVersionCombo($event)"
        class="custom primary required"
        [searchable]="true"
        [clearable]="false"
        [notFoundText]="'No se encontraron datos'"
        appearance="underline"
      >
        <ng-option *ngFor="let version of versions" [value]="version.versionId">
          {{ version.name }}
        </ng-option>
      </ng-select>
    </div>

    <div class="form-group">
      <label>Patente <strong>*</strong></label>
      <input
        type="text"
        class="form-control"
        formControlName="licencePlate"
        placeholder="AB123CD"
        max="191"
      />
    </div>

    <div class="form-group">
      <label>Color <strong>*</strong></label>
      <!-- <input type="text" class="form-control" formControlName="color" max="191" /> -->
      <select class="form-select mr" formControlName="color">
        <option
          *ngFor="let vehicleColor of vehicleColors"
          [value]="vehicleColor.name"
        >
          {{ vehicleColor.name }}
        </option>
      </select>
    </div>

    <div class="form-group">
      <label>Motor <strong>*</strong></label>
      <input
        type="text"
        class="form-control"
        formControlName="motor"
        placeholder="8A1 3CN6AP4 G003230"
        max="191"
      />
    </div>

    <div class="form-group">
      <label>Chasis <strong>*</strong></label>
      <input
        type="text"
        class="form-control"
        formControlName="chasis"
        placeholder="8AD 3CN6AP4 G003230"
        max="191"
      />
    </div>

    <div class="form-group">
      <label
        >Cedula Verde (El lado que figura tu patente!) <strong>*</strong></label
      >

      <div class="row align-items-center mt-2 mb-2">
        <input
          type="file"
          class="form-control"
          accept="image/*"
          (change)="frenteChanged($event)"
        />
      </div>
    </div>

    <div class="error" *ngIf="!!vehicleError">{{ vehicleError }}</div>

    <div class="nav-buttons">
      <button
        type="button"
        *ngIf="this.garageFromWP()"
        class="btn btn-secondary"
        (click)="goPrevious()"
      >
        <fa-icon [icon]="faChevronLeft"></fa-icon> Anterior
      </button>
      <button
      type="button"
      *ngIf="!this.garageFromWP()"
      class="btn btn-secondary"
      (click)="prevStep('address')"
    >
      <fa-icon [icon]="faChevronLeft"></fa-icon> Anterior
    </button>

      <button
      type="button"
      *ngIf="this.garageFromWP()"
      class="btn btn-primary"
      (click)="goClientDetails()"
    >
      Siguiente <fa-icon [icon]="faChevronRight"></fa-icon>
    </button>
      <button
        type="button"
        *ngIf="!this.garageFromWP()"
        [ngClass]="{
          'btn btn-primary-dark': true,
          'loading spinner-white': saving
        }"
        (click)="submitForm()"
      >
        Finalizar
      </button>
    </div>
  </div>
</form>
