<div class="container">
  <div class="row">
    <div class="col-md-6 offset-md-3">
      <h2>Acceder</h2>

      <form class="form-border" [formGroup]="loginForm">
        <div class="form-group">
          <label>Correo electrónico <strong>*</strong></label>
          <input type="text" class="form-control" formControlName="email">
        </div>
        <div class="form-group">
          <label>Contraseña <strong>*</strong></label>
          <input type="password" class="form-control" formControlName="password">
        </div>

        <div class="form-footer">
          <button type="button" [ngClass]="{'btn btn-primary': true, 'loading spinner': loginLoading }" (click)="loginOnSubmit()">Acceder</button>
          <p class="error" *ngIf="!!loginError">{{loginError}}</p>
        </div>
      </form>
    </div>
  </div>
</div>
