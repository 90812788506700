import { ShoppingHistoryService } from './shopping-history.service';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../auth/authentication.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { BaseComponent } from 'src/app/core/components/base-component';
import { OrderService } from 'src/app/shared/services/order.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-shopping-history',
  templateUrl: './shopping-history.component.html',
  styleUrls: ['./shopping-history.component.scss'],
})
export class ShoppingHistoryComponent extends BaseComponent implements OnInit {
  shoppingHistory: any[] = [];
  is_producer: boolean = false;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  loading = false;
  user: any;
  client: any;

  constructor(
    private shoppingHistoryService: ShoppingHistoryService,
    public authenticationService: AuthenticationService,
    private dataService: DataService,
    private location: Location,
    private _router: Router,
    private orderService: OrderService
  ) {
    super();
    if (this.authenticationService.isProducer) this.is_producer = true;
    this.user = this.authenticationService.getUser();
  }

  ngOnInit(): void {
    this.shoppingHistoryService.get().subscribe((res: any) => {
      this.shoppingHistory = res.data;
    });

    this.dataService.getMyClient().subscribe((res: any) => {
      this.client = res.data;

    });
  }
  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }
  goToHome() {
    this._router.navigate(['/branch'], {queryParams:{'client': this.client.clientId}});
  }

  downloadPolicy(order): void {
    this.loading = true;

    this.orderService
      .downloadOrderPolicy(order.id)
      .subscribe((response: any) => {
        if (response.data != null) {
          this.downloadFile(response.data);
        }

        this.loading = false;
      });
  }

  private downloadFile(data): void {
    const link = document.createElement('a');
    link.href = data.base_64;
    link.download = data.file_name;
    link.target = '_self';
    link.click();
  }
}
