import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SuccesComponent } from './succes/succes.component';
import { PaymentComponent } from './payment/payment.component';
import { PendingComponent } from './pending/pending.component';
import { FailureComponent } from './failure/failure.component';
import { SuccessUalaComponent } from './success_uala/success_uala.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'mp/mp_success',
        component: SuccesComponent,
      },
      {
        path: 'mp/mp_success_uala',
        component: SuccessUalaComponent,
      },
      {
        path: 'mp/mp_payment/:responseDataId',
        component: PaymentComponent,
      },
      {
        path: 'mp/mp_failure',
        component: FailureComponent,
      },
      {
        path: 'mp/mp_pending',
        component: PendingComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MpRoutingModule {}
