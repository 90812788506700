import { FormControl, FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class FormService {
  private form: FormGroup
  private startDateAndTime: {startDate: any, startTime: any};
  private selectedClient: any;


  constructor(private datePipe: DatePipe) {

    //I set the start date and time once
    this.resetStartDateAndTime();

    this.form = new FormGroup({
      clientId: new FormControl(),

      branchId: new FormControl(),
      productId: new FormControl(),
      type: new FormControl(),

      firstName: new FormControl(),
      lastName: new FormControl(),
      documentTypeId: new FormControl(),
      documentNumber: new FormControl(),
      birthdate: new FormControl(),
      gender: new FormControl(),
      email: new FormControl(),
      phone: new FormControl(),

      brandId: new FormControl(),
      modelId: new FormControl(),
      yearId: new FormControl(),
      versionId: new FormControl(),

      address: new FormControl(),
      address2: new FormControl(),
      stateId: new FormControl(),
      //city: new FormControl(),
      cityId: new FormControl(),

      startDate: new FormControl(this.startDateAndTime.startDate),
      startTime: new FormControl(this.startDateAndTime.startTime),
      licencePlate: new FormControl(),
      color: new FormControl(),
      motor: new FormControl(),
      chasis: new FormControl(),
      cedulaFrente: new FormControl(),
      garagePrice: new FormControl(),
      //cedulaDorso: new FormControl()
    });
  }

  private getCurrentDatePlusThreeHours(): Date {
    //The current date/time is obtained and the milliseconds equivalent to three hours are added
    var currentDate = new Date()
    var numberOfMlSeconds = currentDate.getTime();
    var addMlSeconds = (4 * 60) * 60000;
    return new Date(numberOfMlSeconds + addMlSeconds);
  }

  addDays(date: Date, days: number): Date {
    var datePlusDays = new Date();
    datePlusDays.setDate(date.getDate() + days);
    return datePlusDays;
  }

  resetStartDateAndTime(): void {
    //I get the current date + three hours once
    var currentDatePlusThreeHours = this.getCurrentDatePlusThreeHours();

    this.startDateAndTime = {
      startDate: undefined,
      startTime: undefined
    }

    //I set the start date and time from the same date + three hours
    this.startDateAndTime.startDate = this.datePipe.transform(currentDatePlusThreeHours,'yyyy-MM-dd');
    this.startDateAndTime.startTime = this.datePipe.transform(currentDatePlusThreeHours,'hh:mm');
  }

  getStartDateAndTime(): {startDate: string, startTime: string} {
    return this.startDateAndTime;
  }

  getForm(): FormGroup {
    return this.form;
  }

  getClient(): any {
    return this.selectedClient;
  }

  setClient(client: any): void {
    this.selectedClient = client;
  }
}
