import { DatePipe } from '@angular/common';
import { ToastService } from 'angular-toastify';
import { MpService } from '../../mp/mp.service';
import { DataService } from '../../../shared/services/data.service';
import { FormService } from '../../../shared/services/form.service';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { AnimationOptions } from 'ngx-lottie';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { BaseComponent } from 'src/app/core/components/base-component';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { OrderService } from 'src/app/shared/services/order.service';

const parseDateTime = (date, time) => {
  if (
    !date ||
    !date.match(/^[\d]{4,4}-[\d]{2,2}-[\d]{2,2}$/) ||
    !time ||
    !time.match(/^[\d]{1,2}:[\d]{1,2}$/)
  )
    return null;

  let dateParts = date.split('-'),
    timeParts = time.split(':');

  return new Date(
    dateParts[0],
    parseInt(dateParts[1]) - 1,
    dateParts[2],
    timeParts[0],
    timeParts[1],
    0,
    0
  );
};

@Component({
  selector: 'app-form-step',
  templateUrl: './form-step.component.html',
  styleUrls: ['./../steps.component.scss'],
})
export class FormStepComponent extends BaseComponent implements OnInit {
  prevStep(arg0: string) {
    this.step = arg0;
  }

  modifiedYears: any;
  modifiedVersions: any;
  modifiedModels: any;
  submitCompleted: any;

  form: FormGroup;
  private loading: boolean = false;
  private frenteFile = null;

  stepsCount = 6;
  currentStep = 4;

  saving = false;
  clientDetailsError: string = null;
  addressError: string = null;
  vehicleError: string = null;
  minDate: any;
  maxDate: any;

  showPhoneAlert: boolean = true;

  vehicleColors = [
    {
      id: 'Plateado',
      name: 'Plateado',
    },
    {
      id: 'Blanco',
      name: 'Blanco',
    },
    {
      id: 'Gris',
      name: 'Gris',
    },
    {
      id: 'Negro',
      name: 'Negro',
    },
    {
      id: 'Rojo',
      name: 'Rojo',
    },
    {
      id: 'Azul',
      name: 'Azul',
    },
    {
      id: 'Verde',
      name: 'Verde',
    },
    {
      id: 'Dorado',
      name: 'Dorado',
    },
    {
      id: 'Otros',
      name: 'Otros',
    },
  ];

  step: string;
  showData: boolean = false;
  client: number;
  branch: number;
  type: number;
  product: number;
  documentTypes: any = [];
  genders: any = [];
  clientList: any = [];
  cities: any = [];
  states: any = [];
  products: any = [];
  productName: string;
  brandName: string;
  models: any = [];
  brands: any = [];
  years: any = [];
  versions: any = [];
  modelName: string;
  yearName: string;
  versionName: string;
  producer: boolean = false;
  paramsObject: any;
  wp: boolean = false;
  selectedClient: any;
  amount: number = 0;
  selectedProduct: any;
  garagePrice: number = 0;

  successLottie: AnimationOptions = {
    path: '/assets/success.json',
    loop: false,
  };
  productor: any;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;

  constructor(
    private formService: FormService,
    private dataService: DataService,
    private orderService: OrderService,
    public mpService: MpService,
    private toastService: ToastService,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    public authenticationService: AuthenticationService,
    private datePipe: DatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.step = 'client-details';

    this._activeRoute.queryParamMap.subscribe((params) => {
      this.paramsObject = { ...params.keys, ...params };

      this.branch = this.paramsObject.params.branch ?? 1;
      this.type = this.paramsObject.params.type ?? 1;
      this.product = this.paramsObject.params.product ?? 6;
      this.showData = (this.paramsObject.showData ?? 'true') == 'true';
      this.client = this.paramsObject.params.client ?? 0;

      if (this.garageFromWP()) {
        this.step = 'vehicle';
      }
    });

    this._activeRoute.params
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((params: Params) => {
        this.wp = (params['wp'] ?? '') == 'wp';

        switch (this.product) {
          case 3:
          case 14:
            this.branch = 1;
            this.type = 3;
            break;
          case 2:
          case 15:
          case 16:
          case 17:
            this.branch = 2;
            this.type = 1;
            break;

          default:
            this.branch = 1;
            this.type = 1;
            break;
        }
      });

    this.producer = this.authenticationService.getUser()?.role == 'producer';

    this.form = this.formService.getForm();
    this.setMinAndMaxStartDate();

    forkJoin(
      this.dataService.getDocumentTypeIds(),
      this.dataService.getProducts(),
      this.dataService.getStates(),
      this.dataService.getGenders(),
      this.dataService.getBrands(this.branch)
    ).subscribe(
      ([
        documentTypesRes,
        productsRes,
        statesRes,
        gendersRes,
        brandsRes,
      ]: any[]) => {
        this.documentTypes = documentTypesRes.data;
        this.products = productsRes.data;
        this.states = statesRes.data;
        this.genders = gendersRes.data;

        this.brands = brandsRes.data.sort((a, b) => {
          return a.best_seller <= b.best_seller
            ? 1
            : -1 && a.name >= b.name
            ? 1
            : -1;
        });
        this.brands = this.brands.sort((a, b) => {
          return a.best_seller <= b.best_seller ? 1 : -1;
        });

        this.form.patchValue({
          documentTypeId: this.documentTypes[0].id,
          stateId: this.states[0].id,
        });

        this.loadCities();

        this.productName = this.getSelectedProduct().short_name;

        if (this.client > 0) {
          this.dataService.getClient(this.client).subscribe((res: any) => {
            this.selectedClient = res.data;

            this.selectBrandCombo(
              this.selectedClient.vehicles[0].model.brand.id
            );
            this.selectModelCombo(this.selectedClient.vehicles[0].model.id);
            this.selectYearComboClient(
              this.selectedClient.vehicles[0].year.id,
              this.selectedClient.vehicles[0].version.id
            );

            this.form.patchValue({
              motor: this.selectedClient.vehicles[0].motor,
              chasis: this.selectedClient.vehicles[0].chasis,
              licencePlate: this.selectedClient.vehicles[0].licencePlate,
              color: this.capitalizeFirstLetter(
                this.selectedClient.vehicles[0].color
              ),
              cedulaFrente: this.selectedClient.vehicles[0].documents[0].file,

              lastName: this.selectedClient.lastName,
              firstName: this.selectedClient.firstName,
              email: this.selectedClient.email,
              phone: this.selectedClient.phone,
              documentNumber: this.selectedClient.documentNumber,
              documentTypeId: this.selectedClient.documentTypeId,
              birthdate: this.selectedClient.birthdate,
              gender: this.selectedClient.clientGender.id,
              stateId: this.selectedClient.stateId,
              address: this.selectedClient.address,
              address2: this.selectedClient.address2,
            });

            this.loadCitiesClients(this.selectedClient.cityId);

            this.frenteFile = this.selectedClient.vehicles[0].documents[0];
          });
        }

        if (this.garageFromWP()) {
          this.selectedProduct = this.products.find(
            (product) => product.productId == this.product
          );
        }

        this.loading = false;
      }
    );
  }

  garageFromWP() {
    //return this.wp && this.type == 3;
    return this.product == 3 || this.product == 14;
  }

  getSelectedProduct() {
    return this.products.find((product) => product.productId == this.product);
  }

  setMinAndMaxStartDate(): void {
    this.minDate = this.formService.getStartDateAndTime().startDate;
    this.maxDate = this.datePipe.transform(
      this.formService.addDays(
        parseDateTime(
          this.formService.getStartDateAndTime().startDate,
          this.formService.getStartDateAndTime().startTime
        ),
        30
      ),
      'yyyy-MM-dd'
    );
  }

  goPrevious() {
    this._router.navigate(['']);
  }

  clientDetailsNextOnClick() {
    this.currentStep = 5;
    let v = this.form.value;

    const e = (message) => (this.clientDetailsError = message);

    e(null);

    var lastNameValidationMessage = this.validateLastName(v);
    if (lastNameValidationMessage != '') {
      return e(lastNameValidationMessage);
    }

    var firstNameValidationMessage = this.validateFirstName(v);
    if (firstNameValidationMessage != '') {
      return e(firstNameValidationMessage);
    }

    if (!v.email?.trim() || !v.email.match(/^.+?@.+?(\.+)+/))
      return e('Ingresá un correo electrónico válido.');

    var phoneValidationMessage = this.validateCellPhone();
    if (phoneValidationMessage != '') {
      return e(phoneValidationMessage);
    }

    var documentNumberMessage = this.validateDocumentNumber(v);
    if (documentNumberMessage != '') {
      return e(documentNumberMessage);
    }

    this.step = 'address';
  }

  clearClientErrorMessage(): void {
    const e = (message) => (this.clientDetailsError = message);
    e(null);
  }

  validateControlAlerts(): void {
    this.validateCellPhone();
  }

  validateFirstName(formValue: any): string {
    if (!formValue.firstName || formValue.firstName.trim().length < 4) {
      return 'Ingresá el nombre completo. (Al menos 4 caracteres)';
    }

    return '';
  }

  validateLastName(formValue: any): string {
    if (!formValue.lastName || formValue.lastName.trim().length < 4) {
      return 'Ingresá el apellido. (Al menos 4 caracteres)';
    }

    return '';
  }

  validateCellPhone(): string {
    this.showPhoneAlert = false;
    this.clearClientErrorMessage();

    let v = this.form.value;

    if (!v.phone || v.phone.trim().length != 10) {
      this.showPhoneAlert = true;
      return 'Ingresá un número de celular válido.';
    }

    return '';
  }

  validateDocumentNumber(formValue: any): string {
    //Validate data length
    if (
      !formValue.documentNumber ||
      formValue.documentNumber.trim().length < 7
    ) {
      return 'Ingresá un número de documento válido.';
    }

    //Validate data range
    if (
      formValue.documentNumber < 3000000 ||
      formValue.documentNumber > 99999999
    ) {
      return 'Ingresá un número de documento válido.';
    }

    if (
      formValue.documentNumber > 45000000 &&
      formValue.documentNumber < 92000000
    ) {
      return 'Ingresá un número de documento válido.';
    }

    return '';
  }

  addressNextOnClick() {
    this.currentStep = 6;
    let v = this.form.value;

    const e = (message) => (this.addressError = message);

    e(null);

    var streetAndNumberMessage = this.validateStreetAndNumber(v);
    if (streetAndNumberMessage != '') {
      return e(streetAndNumberMessage);
    }

    if (!v.stateId || !v.cityId)
      return e('Seleccioná la provincia y la ciudad.');

    this.step = 'vehicle';
  }

  clearAddressErrorMessage(): void {
    const e = (message) => (this.addressError = message);
    e(null);
  }

  validateStreetAndNumber(formValue: any): string {
    if (!formValue.address || formValue.address.trim().length < 4) {
      return 'Ingresá calle y número. (Al menos 4 caracteres)';
    }

    return '';
  }

  validateApartment(formValue: any): string {
    if (!formValue.address2 || formValue.address2.trim().length < 4) {
      return 'Ingresá piso, departamento y bloque. (Al menos 4 caracteres)';
    }

    return '';
  }

  loadCities() {
    this.dataService
      .getCities(this.form.value.stateId)
      .subscribe((res: any) => {
        this.cities = res.data;
        if (!this.form.value.cityId)
          this.form.patchValue({ cityId: this.cities[0]?.id });
      });
  }

  loadCitiesClients(cityId) {
    this.dataService
      .getCities(this.form.value.stateId)
      .subscribe((res: any) => {
        this.cities = res.data;
        this.form.patchValue({ cityId: cityId });
      });
  }

  get selectedBrandId(): number {
    return this.form.value.brandId;
  }

  get selectedModelId(): number {
    return this.form.value.modelId;
  }

  get selectedYearId(): number {
    return this.form.value.yearId;
  }

  get selectedVersionId(): number {
    return this.form.value.versionId;
  }

  selectBrandCombo(brand) {
    this.form.patchValue({ brandId: brand });
    this.loading = true;
    this.dataService.getModels(brand).subscribe((res: any) => {
      this.models = res.data.sort((a, b) => {
        return a.name >= b.name ? 1 : -1;
      });
      this.models = this.models.sort((a, b) => {
        return a.best_seller <= b.best_seller ? 1 : -1;
      });
      //this.modifiedModels.emit(this.models);
      this.loading = false;
    });
  }

  selectModelCombo(model) {
    this.form.patchValue({ modelId: model });
    this.loading = true;
    this.dataService.getYears(model).subscribe((res: any) => {
      this.years = res.data;
      //this.modifiedYears.emit(this.years);
      this.loading = false;
    });
  }

  selectYearCombo(year) {
    this.form.patchValue({ yearId: year });
    this.loading = true;
    this.dataService
      .getVersions(this.form.value.modelId, year)
      .subscribe((res: any) => {
        this.versions = res.data.sort((a, b) => {
          return a.name >= b.name ? 1 : -1;
        });
        //this.modifiedVersions.emit(this.versions);
        this.loading = false;
      });
  }

  selectYearComboClient(year, version) {
    this.form.patchValue({ yearId: year });
    this.loading = true;
    this.dataService
      .getVersions(this.form.value.modelId, year)
      .subscribe((res: any) => {
        this.versions = res.data.sort((a, b) => {
          return a.name >= b.name ? 1 : -1;
        });
        //this.modifiedVersions.emit(this.versions);

        this.selectVersionCombo(version);

        this.loading = false;
      });
  }

  selectVersionCombo(version) {
    if (this.garageFromWP()) {
      var versionModel = this.versions.find((ver) => ver.versionId == version);
      this.amount = versionModel != null ? versionModel.amount : 0;
    }

    this.form.patchValue({ versionId: version });
  }

  frenteChanged(ev) {
    this.frenteFile = ev.target.files[0];
  }

  goClientDetails() {
    this.currentStep = 5;

    //#region validation
    let v = this.form.value;

    const e = (message) => (this.vehicleError = message);

    e(null);

    if (
      !parseDateTime(v.startDate, v.startTime) ||
      parseDateTime(v.startDate, v.startTime) <
        parseDateTime(
          this.formService.getStartDateAndTime().startDate,
          this.formService.getStartDateAndTime().startTime
        )
    )
      return e(
        'La fecha de inicio no puede ser anterior a: ' +
          this.datePipe.transform(
            this.formService.getStartDateAndTime().startDate,
            'dd-MM-yyyy'
          ) +
          ' ' +
          this.formService.getStartDateAndTime().startTime
      );

    if (
      !v.licencePlate ||
      (!v.licencePlate.match(/^[a-zA-Z]{3,3}[0-9]{3,3}$/) &&
        !v.licencePlate.match(/^[a-zA-Z]{2,2}[0-9]{3,3}[a-zA-Z]{2,2}$/))
    )
      return e('Ingresá la patente en formato abc123 o ab123bcd.');

    if (!v.color) return e('Ingresá el color.');

    // if (!v.motor) return e('Ingresá el número de motor.');
    var motorValidationMessage = this.validateMotor(v);
    if (motorValidationMessage != '') {
      return e(motorValidationMessage);
    }

    // if (!v.chasis) return e('Ingresá el número de chasis.');
    var chasisValidationMessage = this.validateChasis(v);
    if (chasisValidationMessage != '') {
      return e(chasisValidationMessage);
    }

    if (!this.frenteFile)
      return e(
        'Seleccioná las fotos o escaneos de la cédula de identificación del vehículo.'
      );

    //#endregion

    this.step = 'client-details';

    this.loading = true;

    var priceData = {
      amount: this.amount,
      days: this.selectedProduct != null ? this.selectedProduct.days : 0,
    };
    this.orderService.getGarageAmount(priceData).subscribe((res: any) => {
      if (res.status) {
        this.garagePrice = res.price;
        this.form.patchValue({
          garagePrice: this.garagePrice,
        });
      }

      this.loading = false;
    });
  }

  submitForm() {
    if (this.saving) return;

    let v = this.form.value;

    const e = (message) => (this.vehicleError = message);

    e(null);

    if (
      !parseDateTime(v.startDate, v.startTime) ||
      parseDateTime(v.startDate, v.startTime) <
        parseDateTime(
          this.formService.getStartDateAndTime().startDate,
          this.formService.getStartDateAndTime().startTime
        )
    )
      return e(
        'La fecha de inicio no puede ser anterior a: ' +
          this.datePipe.transform(
            this.formService.getStartDateAndTime().startDate,
            'dd-MM-yyyy'
          ) +
          ' ' +
          this.formService.getStartDateAndTime().startTime
      );

    if (
      !v.licencePlate ||
      (!v.licencePlate.match(/^[a-zA-Z]{3,3}[0-9]{3,3}$/) &&
        !v.licencePlate.match(/^[a-zA-Z]{2,2}[0-9]{3,3}[a-zA-Z]{2,2}$/))
    )
      return e('Ingresá la patente en formato abc123 o ab123bcd.');

    if (!v.color) return e('Ingresá el color.');

    // if (!v.motor) return e('Ingresá el número de motor.');
    var motorValidationMessage = this.validateMotor(v);
    if (motorValidationMessage != '') {
      return e(motorValidationMessage);
    }

    // if (!v.chasis) return e('Ingresá el número de chasis.');
    var chasisValidationMessage = this.validateChasis(v);
    if (chasisValidationMessage != '') {
      return e(chasisValidationMessage);
    }

    if (!this.frenteFile)
      return e(
        'Seleccioná las fotos o escaneos de la cédula de identificación del vehículo.'
      );

    this.saving = true;
    this.form.patchValue({
      branchId: this.branch,
      clientId: null,
      productId: this.product,
      type: this.type,
    });

    forkJoin(
      this.dataService.uploadFile('cedula', this.frenteFile)
      //this.service.uploadFile("cedula", this.dorsoFile)
    ).subscribe(
      ([frenteRes]: any[]) => {
        this.form.patchValue({
          cedulaFrente: frenteRes.data.url,
          //cedulaDorso: dorsoRes.data.url
        });

        this.dataService.submit(this.form.value).subscribe(
          (res: any) => {
            //LLAMAR a MP para el pago
            this.mpService
              .getPreferenceId(res.data.id)
              .subscribe((response: any) => {
                if (response.error) {
                  //TODO: ERROR al GENERAR el PAGO
                } else {
                  //SET THE PREFERENCE ID;
                  //this.preferenceId=

                  this.formSubmitCompleted(response.data.id);
                  this.saving = false;
                }
              });
          },
          (res) => {
            this.saving = false;
            this.toastService.error(
              res.error?.data?.message || 'Imposible conectar con el servidor.'
            );
          }
        );
      },
      (res) => {
        this.saving = false;
        e(res.error?.data?.message || 'Imposible conectar con el servidor.');
      }
    );
  }

  validateMotor(formValue: any): string {
    if (!formValue.motor || formValue.motor.trim().length < 10) {
      return 'Ingresá el motor. (Al menos 10 caracteres)';
    }

    return '';
  }

  validateChasis(formValue: any): string {
    if (!formValue.chasis || formValue.chasis.trim().length < 10) {
      return 'Ingresá el chasis. (Al menos 10 caracteres)';
    }

    return '';
  }

  clearVehicleErrorMessage(): void {
    const e = (message) => (this.vehicleError = message);
    e(null);
  }

  formSubmitCompleted(responseDataId: any) {
    this._router.navigate([`mp/mp_payment/${responseDataId}`]);
    //this.loadScipts(responseDataId);
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
}
