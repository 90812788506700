import {Component, OnInit} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {AuthenticationService} from '../authentication.service';

@Component({
  selector: 'app-login-register-page',
  templateUrl: './login-register-page.component.html',
  styleUrls: [ './login-register-page.component.css' ],
})
export class LoginRegisterPageComponent implements OnInit {
  public loginForm: FormGroup;
  public loginLoading: Boolean = false;
  public loginError: String = null;

  public registerForm: FormGroup;
  public registerLoading: Boolean = false;
  public registerError: String = null;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl(''),
      password: new FormControl(''),
    });

    this.registerForm = new FormGroup({
      name: new FormControl(''),
      email: new FormControl(''),
      password: new FormControl(''),
      confirmPassword: new FormControl('')
    });
  }

  public loginOnSubmit() {
    this.loginLoading = true;
    this.loginError = null;

    this.authenticationService.login(this.loginForm.value, err => {
      this.loginLoading = false;
      this.loginError = err;
    });
  }

  public registerOnSubmit() {
    this.registerLoading = true;
    this.registerError = null;

    this.authenticationService.register(this.registerForm.value, err => {
      this.registerLoading = false;
      this.registerError = err;
    });    
  }
}
