import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepsModule } from '../steps/steps.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShoppingHistoryComponent } from './shopping-history.component';
import { ShoppingHistoryRoutingRoutingModule } from './shopping-history-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
    declarations: [ShoppingHistoryComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ShoppingHistoryRoutingRoutingModule,
        StepsModule,
        FontAwesomeModule,
    ]
})
export class ShoppingHistoryModule {
}
