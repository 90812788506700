<div class="loading-modal spinner-lg" *ngIf="loading"></div>

<div class="container narrow">
  <div class="header">
    <h2>Qué tipo de producto vas a usar</h2>
    <app-dots [steps]="stepsCount" [active]="currentStep"></app-dots>
  </div>

  <div class="form-group buttons-list">
    <button
      type="button"
      class="btn btn-product"
      style="flex: 100%"
      *ngFor="let type of types"
      (click)="selectType(type)"
    >
      {{ type.name }}
    </button>
  </div>

  <button
    type="button"
    class="btn btn-secondary"
    (click)="prevStep()"
  >
    <fa-icon [icon]="faChevronLeft"></fa-icon> Anterior
  </button>
</div>
